<template>
  <div>
    <transition name="van-slide-left">
      <keep-alive>
        <Left v-if="test == 2" />
      </keep-alive>
    </transition>
    <transition name="van-slide-right">
      <keep-alive>
        <Right v-if="test == 2"  />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import Left from "./Left12.vue";
import Right from "./Right12.vue";
import {
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  onActivated,
  onDeactivated,
} from "vue";
import { ref, reactive, toRefs, watch, computed } from "vue";
import { useStore, mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { useRouter } from "vue-router";

export default {
  components: {
    Right,
    Left,
  },
  setup() {
    const _this = getCurrentInstance().proxy;
    const router = useRouter();
    const store = useStore();
    const test = computed(() => {
      return store.state.test;
    });
    const state = reactive({
      count: 0,
    });

    return {
      ...toRefs(state),
      test
    };
  },
};
</script>

<style  scoped>
</style>