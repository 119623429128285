<template>
        <div class="top">左边</div>
</template>

<script>
import { reactive, toRefs } from 'vue'

export default {
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            ...toRefs(state),
        }
    }
}
</script>

<style  scoped>
.top{
    width: 500px;
    height: 100vh;
    background-color: yellow;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 10;
}
</style>